import React from "react"
import Layout from "../components/layout"
import Nav from "../components/nav"
import world from "../images/undraw/world.svg"

const LearnMore = () => {
  return (
    <Layout>
      <div className="h-screen gradient py-24">
        <div className="mx-auto shadow-custom p-8 rounded bg-white flex w-4/5 mx-auto">
          <div className="w-2/3 mr-8">
            <h2>How Can We Help?</h2>
            <p className="w-4/5">
              We know it can hard to wrap your head around data and how explot
              fits in it, especially when starting out. Data is our passion and
              increasing the understanding of data is our mission. So don't
              heistate to reach out with any questions you have, we are happy to
              help.
            </p>
          </div>
          <form
            className="w-4/5 md:w-1/2 max-w-xs mx-auto p-8 rounded bg-white"
            name="learn-more"
            method="POST"
            //@ts-ignore
            data-netlify="true"
          >
            <input
              type="hidden"
              className="hidden"
              name="form-name"
              value="learn-more"
            />
            <h2>Learn More</h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="email"
              >
                E-mail
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                name="email"
                placeholder="Your e-mail"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="email"
              >
                What are you wondering about?
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="textarea"
                name="textarea"
                placeholder="..."
              ></textarea>
            </div>
            <div className="flex items-center justify-between">
              <input
                className="items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
                type="submit"
                value="Send"
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default LearnMore
